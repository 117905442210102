export class RegistroManutencaoTrocaPecasModel {
  constructor({
    id,
    instrumentoId,
    localManutencao,
    participanteFornecedorId,
    tipoManutencao,
    ordemCompraManutencao,
    servicoId,
    servicoDescricao,
    valorManutencao,
    dataEnvioInstrumento,
    prazoAcordado,
    tipoPrazoAcordado,
    dataPrevistaRetorno,
    dataRetorno,
    ocorrenciaId,
    flagNecessitaTrocaPeca,
    itemId,
    itemDescricao,
    valorPeca,
    ordemCompraPeca,
    observacao,
    usuarioRegistroId,
    usuarioRegistroNome,
  }) {
    this.id = id;
    this.instrumentoId = instrumentoId;
    this.localManutencao = localManutencao;
    this.participanteFornecedorId = participanteFornecedorId;
    this.tipoManutencao = tipoManutencao;
    this.ordemCompraManutencao = ordemCompraManutencao;
    this.servicoId = servicoId;
    this.servicoDescricao = servicoDescricao;
    this.valorManutencao = valorManutencao;
    this.dataEnvioInstrumento = dataEnvioInstrumento;
    this.prazoAcordado = prazoAcordado;
    this.tipoPrazoAcordado = tipoPrazoAcordado;
    this.dataPrevistaRetorno = dataPrevistaRetorno;
    this.dataRetorno = dataRetorno;
    this.ocorrenciaId = ocorrenciaId;
    this.flagNecessitaTrocaPeca = flagNecessitaTrocaPeca;
    this.itemId = itemId;
    this.itemDescricao = itemDescricao;
    this.valorPeca = valorPeca;
    this.ordemCompraPeca = ordemCompraPeca;
    this.observacao = observacao;
    this.usuarioRegistroId = usuarioRegistroId;
    this.usuarioRegistroNome = usuarioRegistroNome;
  }
}
