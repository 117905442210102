var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex mb-8"},[_c('div',{staticClass:"titulo-pagina"},[_vm._v(" "+_vm._s(_vm.tituloFormulario)+" ")])]),_c('v-form',{ref:"form",staticClass:"row d-flex"},[_c('accordion-padrao',{staticClass:"col-12",attrs:{"nao-retrair":"","cor-azul-texto":"","alterar-cor":"","titulo":_vm.$t('modulos.registro_manutencao_troca_pecas.dados')}},[_c('div',{staticClass:"row"},[_c('InputInstrumento',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.instrumento'
          ),"obrigatorio":"","multiplos":false,"max":100,"disabled":!!this.$route.params.instrumentoId},model:{value:(_vm.form.instrumento),callback:function ($$v) {_vm.$set(_vm.form, "instrumento", $$v)},expression:"form.instrumento"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.local_manutencao'
          ),"options":_vm.opcoes.localManutencao,"obrigatorio":""},model:{value:(_vm.form.localManutencao),callback:function ($$v) {_vm.$set(_vm.form, "localManutencao", $$v)},expression:"form.localManutencao"}}),_c('InputParticipantes',{key:_vm.keyAtualiza,staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.fornecedor'
          ),"obrigatorio":"","disabled":_vm.desabilitaParticipanteFornecedor,"multiplos":false,"max":100,"flag-fornecedor":2},on:{"input":_vm.definirparticipanteFornecedorId},model:{value:(_vm.form.participante),callback:function ($$v) {_vm.$set(_vm.form, "participante", $$v)},expression:"form.participante"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.tipo_manutencao'
          ),"options":_vm.opcoes.tipoManutencao,"obrigatorio":""},model:{value:(_vm.form.tipoManutencao),callback:function ($$v) {_vm.$set(_vm.form, "tipoManutencao", $$v)},expression:"form.tipoManutencao"}}),_c('input-date',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.data_envio'
          ),"obrigatorio":""},model:{value:(_vm.form.dataEnvioInstrumento),callback:function ($$v) {_vm.$set(_vm.form, "dataEnvioInstrumento", $$v)},expression:"form.dataEnvioInstrumento"}}),_c('input-select',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.tipo_prazo_acordado'
          ),"options":_vm.opcoes.prazoAcordado,"obrigatorio":""},model:{value:(_vm.form.tipoPrazoAcordado),callback:function ($$v) {_vm.$set(_vm.form, "tipoPrazoAcordado", $$v)},expression:"form.tipoPrazoAcordado"}}),_c('input-text',{staticClass:"col-12 col-md-1",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.prazo_acordado'
          ),"obrigatorio":"","type":"number","max":100},model:{value:(_vm.form.prazoAcordado),callback:function ($$v) {_vm.$set(_vm.form, "prazoAcordado", $$v)},expression:"form.prazoAcordado"}}),_c('input-date',{key:_vm.keyAtualizaDataPrevistaRetorno,staticClass:"col-12 col-md-2",attrs:{"regras-personalizadas":[_vm.validarDatasRetorno],"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.data_prev_retorno'
          ),"obrigatorio":""},model:{value:(_vm.form.dataPrevistaRetorno),callback:function ($$v) {_vm.$set(_vm.form, "dataPrevistaRetorno", $$v)},expression:"form.dataPrevistaRetorno"}}),_c('input-date',{staticClass:"col-12 col-md-2",attrs:{"regras-personalizadas":[_vm.validarDatasRetorno],"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.data_retorno'
          )},model:{value:(_vm.form.dataRetorno),callback:function ($$v) {_vm.$set(_vm.form, "dataRetorno", $$v)},expression:"form.dataRetorno"}}),_c('InputOcorrencia',{staticClass:"col-12 col-md-3",attrs:{"multiplos":false,"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.ocorrencia'
          ),"max":100},model:{value:(_vm.form.ocorrencia),callback:function ($$v) {_vm.$set(_vm.form, "ocorrencia", $$v)},expression:"form.ocorrencia"}})],1)]),_c('accordion-padrao',{staticClass:"col-12 pb-0",attrs:{"nao-retrair":"","cor-azul-texto":"","alterar-cor":"","titulo":_vm.$t('modulos.registro_manutencao_troca_pecas.manutencao')}},[_c('div',{staticClass:"row"},[_c('input-text',{staticClass:"col-12 col-md-8",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.descricao_manutencao'
          ),"obrigatorio":"","max":100},model:{value:(_vm.form.servicoDescricao),callback:function ($$v) {_vm.$set(_vm.form, "servicoDescricao", $$v)},expression:"form.servicoDescricao"}}),_c('input-money',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.valor_manutencao'
          ),"options":_vm.optionsInputMoney},model:{value:(_vm.form.valorManutencao),callback:function ($$v) {_vm.$set(_vm.form, "valorManutencao", $$v)},expression:"form.valorManutencao"}}),_c('input-text',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.ordem_compra'
          ),"max":100},on:{"input":_vm.ajustaOrdemCompraPeca},model:{value:(_vm.form.ordemCompraManutencao),callback:function ($$v) {_vm.$set(_vm.form, "ordemCompraManutencao", $$v)},expression:"form.ordemCompraManutencao"}})],1)]),_c('accordion-padrao',{staticClass:"col-12 pt-0",attrs:{"nao-retrair":"","cor-azul-texto":"","alterar-cor":"","titulo":_vm.$t('modulos.registro_manutencao_troca_pecas.pecas')}},[_c('div',{staticClass:"row"},[_c('input-text',{staticClass:"col-12 col-md-7",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.descricao_peca'
          ),"max":100},model:{value:(_vm.form.itemDescricao),callback:function ($$v) {_vm.$set(_vm.form, "itemDescricao", $$v)},expression:"form.itemDescricao"}}),_c('input-money',{staticClass:"col-12 col-md-1",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.valor_peca'
          ),"options":_vm.optionsInputMoney},model:{value:(_vm.form.valorPeca),callback:function ($$v) {_vm.$set(_vm.form, "valorPeca", $$v)},expression:"form.valorPeca"}}),_c('input-text',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.ordem_compra_peca'
          ),"max":100},model:{value:(_vm.form.ordemCompraPeca),callback:function ($$v) {_vm.$set(_vm.form, "ordemCompraPeca", $$v)},expression:"form.ordemCompraPeca"}}),_c('input-radio',{staticClass:"col-12 col-md-2",attrs:{"divider":false,"options":_vm.opcoes.booleano,"label":_vm.$t(
            'modulos.registro_manutencao_troca_pecas.formulario.troca_pecas'
          )},model:{value:(_vm.form.flagNecessitaTrocaPeca),callback:function ($$v) {_vm.$set(_vm.form, "flagNecessitaTrocaPeca", $$v)},expression:"form.flagNecessitaTrocaPeca"}})],1)]),_c('input-text',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.registro_manutencao_troca_pecas.formulario.obervacoes'),"obrigatorio":"","max":100},model:{value:(_vm.form.observacao),callback:function ($$v) {_vm.$set(_vm.form, "observacao", $$v)},expression:"form.observacao"}}),_c('input-text',{staticClass:"col-12 col-md-6",attrs:{"label":_vm.$t('modulos.registro_manutencao_troca_pecas.formulario.usuario'),"disabled":""},model:{value:(_vm.form.usuarioRegistroNome),callback:function ($$v) {_vm.$set(_vm.form, "usuarioRegistroNome", $$v)},expression:"form.usuarioRegistroNome"}})],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('botao-padrao',{staticClass:"mr-2",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.cancelar}},[_c('v-icon',[_vm._v("$mdiCancel")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.cancelar'))+" ")],1),_c('botao-padrao',{on:{"click":_vm.confirmacaoSalvar}},[_c('v-icon',[_vm._v("$mdiContentSaveOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.salvar'))+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }