<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form
      ref="form"
      class="row d-flex"
    >
      <accordion-padrao
        nao-retrair
        cor-azul-texto
        alterar-cor
        class="col-12"
        :titulo="$t('modulos.registro_manutencao_troca_pecas.dados')"
      >
        <div class="row">
          <InputInstrumento
            v-model="form.instrumento"
            class="col-12 col-md-3"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.instrumento'
            )
            "
            obrigatorio
            :multiplos="false"
            :max="100"
            :disabled="!!this.$route.params.instrumentoId"
          />
          <input-select
            v-model="form.localManutencao"
            class="col-12 col-md-3"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.local_manutencao'
            )
            "
            :options="opcoes.localManutencao"
            obrigatorio
          />
          <InputParticipantes
            :key="keyAtualiza"
            v-model="form.participante"
            class="col-12 col-md-3"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.fornecedor'
            )
            "
            obrigatorio
            :disabled="desabilitaParticipanteFornecedor"
            :multiplos="false"
            :max="100"
            :flag-fornecedor="2"
            @input="definirparticipanteFornecedorId"
          />
          <input-select
            v-model="form.tipoManutencao"
            class="col-12 col-md-3"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.tipo_manutencao'
            )
            "
            :options="opcoes.tipoManutencao"
            obrigatorio
          />
          <input-date
            v-model="form.dataEnvioInstrumento"
            class="col-12 col-md-2"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.data_envio'
            )
            "
            obrigatorio
          />
          <input-select
            v-model="form.tipoPrazoAcordado"
            class="col-12 col-md-2"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.tipo_prazo_acordado'
            )
            "
            :options="opcoes.prazoAcordado"
            obrigatorio
          />
          <input-text
            v-model="form.prazoAcordado"
            class="col-12 col-md-1"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.prazo_acordado'
            )
            "
            obrigatorio
            type="number"
            :max="100"
          />
          <input-date
            :key="keyAtualizaDataPrevistaRetorno"
            v-model="form.dataPrevistaRetorno"
            :regras-personalizadas="[validarDatasRetorno]"
            class="col-12 col-md-2"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.data_prev_retorno'
            )
            "
            obrigatorio
          />
          <input-date
            v-model="form.dataRetorno"
            :regras-personalizadas="[validarDatasRetorno]"
            class="col-12 col-md-2"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.data_retorno'
            )
            "
          />
          <InputOcorrencia
            v-model="form.ocorrencia"
            class="col-12 col-md-3"
            :multiplos="false"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.ocorrencia'
            )
            "
            :max="100"
          />
        </div>
      </accordion-padrao>
      <accordion-padrao
        nao-retrair
        cor-azul-texto
        alterar-cor
        class="col-12 pb-0"
        :titulo="$t('modulos.registro_manutencao_troca_pecas.manutencao')"
      >
        <div class="row">
          <input-text
            v-model="form.servicoDescricao"
            class="col-12 col-md-8"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.descricao_manutencao'
            )
            "
            obrigatorio
            :max="100"
          />
          <input-money
            v-model="form.valorManutencao"
            class="col-12 col-md-2"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.valor_manutencao'
            )
            "
            :options="optionsInputMoney"
          />
          <input-text
            v-model="form.ordemCompraManutencao"
            class="col-12 col-md-2"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.ordem_compra'
            )
            "
            :max="100"
            @input="ajustaOrdemCompraPeca"
          />
        </div>
      </accordion-padrao>
      <accordion-padrao
        nao-retrair
        cor-azul-texto
        alterar-cor
        class="col-12 pt-0"
        :titulo="$t('modulos.registro_manutencao_troca_pecas.pecas')"
      >
        <div class="row">
          <input-text
            v-model="form.itemDescricao"
            class="col-12 col-md-7"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.descricao_peca'
            )
            "
            :max="100"
          />
          <input-money
            v-model="form.valorPeca"
            class="col-12 col-md-1"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.valor_peca'
            )
            "
            :options="optionsInputMoney"
          />
          <input-text
            v-model="form.ordemCompraPeca"
            class="col-12 col-md-2"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.ordem_compra_peca'
            )
            "
            :max="100"
          />
          <input-radio
            v-model="form.flagNecessitaTrocaPeca"
            class="col-12 col-md-2"
            :divider="false"
            :options="opcoes.booleano"
            :label="$t(
              'modulos.registro_manutencao_troca_pecas.formulario.troca_pecas'
            )
            "
          />
        </div>
      </accordion-padrao>
      <input-text
        v-model="form.observacao"
        class="col-12 col-md-6"
        :label="$t('modulos.registro_manutencao_troca_pecas.formulario.obervacoes')
        "
        obrigatorio
        :max="100"
      />
      <input-text
        v-model="form.usuarioRegistroNome"
        class="col-12 col-md-6"
        :label="$t('modulos.registro_manutencao_troca_pecas.formulario.usuario')
        "
        disabled
      />
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import RegistroManutencaoTrocaPecaService from '@common/services/cadastros/RegistroManutencaoTrocaPecaService';
import { RegistroManutencaoTrocaPecasModel } from '@common/models/cadastros/RegistroManutencaoTrocaPecasModel';
import helpers from '@common/utils/helpers';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import moment from 'moment';
moment.locale('pt-br');
import {
  InputInstrumento,
  InputParticipantes,
  InputOcorrencia,
} from '@components/inputs';
import { EnumModel } from '@common/models/geral/EnumModel';
export default {
  components: {
    InputInstrumento,
    InputParticipantes,
    InputOcorrencia,
  },
  props: {
    id: { type: String, default: '' },
  },
  data() {
    return {
      optionsInputMoney: {
        locale: 'pt-BR',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 2,
      },
      valido: false,
      desabilitaParticipanteFornecedor: false,
      keyAtualiza: 0,
      keyAtualizaDataPrevistaRetorno: 50,
      form: new RegistroManutencaoTrocaPecasModel({}),
      opcoes: {
        localManutencao: [],
        tipoManutencao: [],
        prazoAcordado: [],
        booleano: helpers.BoleanoEnum,
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id)
        return this.$t(
          'modulos.registro_manutencao_troca_pecas.titulos.editar'
        );
      return this.$t('modulos.registro_manutencao_troca_pecas.titulos.novo');
    },
  },
  watch: {
    'form.instrumento': function (valor) {
      if (valor?.value) this.form.instrumentoId = valor.value;
    },
    'form.ocorrencia': function (valor) {
      if (valor?.value) this.form.ocorrenciaId = valor.value;
    },
    'form.localManutencao': {
      handler(newValue, oldValue) {
        if (newValue != null && oldValue != null)
          this.validaParticipante(newValue);
      },
      deep: true,
      immediate: true
    },
    'form.participante': {
      handler(valor) {
        this.definirparticipanteFornecedorId(valor);
      },
      deep: true,
      immediate: true
    },
    'form.dataEnvioInstrumento'() {
      this.calculaDataPrevistaRetorno();
    },
    'form.prazoAcordado'() {
      this.calculaDataPrevistaRetorno();
    },
    'form.tipoPrazoAcordado'() {
      this.calculaDataPrevistaRetorno();
    },
  },
  mounted() {
    this.form.flagNecessitaTrocaPeca = false;
    this.form.usuarioRegistroId = localStorage.getItem('usuarioId');
    this.form.usuarioRegistroNome = localStorage.getItem('usuarioNome');
    if (this.$route.params.instrumentoId) {
      this.form.instrumentoId = this.$route.params.instrumentoId;
      this.form.instrumento = {
        value: this.$route.params.instrumentoId,
        text: this.$route.params.codigo,
      };
      this.form.instrumento.value = this.$route.params.instrumentoId;
      this.form.instrumento.text = this.$route.params.codigo;
    }
    this.listarLocalManutencao();
    this.listarTipoManutencao();
    this.listarPrazoAcordado();
    if (this.id) {
      this.buscar(this.id)
      helpers.redirecionarSemPermissao(this, 'InstrumentoRegistroManutencaoTrocaPeca', 'Editar')
    }
    helpers.redirecionarSemPermissao(this, 'InstrumentoRegistroManutencaoTrocaPeca', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    ajustaOrdemCompraPeca() {
      this.form.ordemCompraPeca = this.form.ordemCompraManutencao;
    },
    calculaDataPrevistaRetorno() {
      if (
        this.form.dataEnvioInstrumento &&
        this.form.prazoAcordado &&
        this.form.tipoPrazoAcordado
      ) {
        let dataEnvio = moment(this.form.dataEnvioInstrumento);
        let prazo = Number(this.form.prazoAcordado);

        let dataPrevistaRetorno;

        if (this.form.tipoPrazoAcordado === 'DiasUteis') {
          dataPrevistaRetorno = helpers.adicionarDiasUteis(dataEnvio, prazo);
        } else {
          dataPrevistaRetorno = dataEnvio.clone().add(prazo, 'days');
        }

        this.form.dataPrevistaRetorno = dataPrevistaRetorno.toDate();

        this.keyAtualizaDataPrevistaRetorno++;
      }
    },
    definirparticipanteFornecedorId(valor) {
      if (valor?.value) this.form.participanteFornecedorId = valor.value;
    },
    validaParticipante(localManutencao) {
      if (localManutencao === 'Interna') {
        this.buscaParticipanteApelido();
      } else {
        this.form.participanteFornecedorId = null;
        this.form.participante = null;
        this.keyAtualiza++;
        this.desabilitaParticipanteFornecedor = false;
      }
    },
    validarDatasRetorno(v) {
      if (!v || !this.form.dataEnvioInstrumento) return true;
      if (
        moment(v, 'DD-MM-YYYY').format() <
        this.form.dataEnvioInstrumento.substring(0, 10)
      )
        return 'Data menor que a Data Envio';
      return true;
    },
    buscaParticipanteApelido() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscar(this.form.participanteFornecedorId ?? localStorage.getItem('participanteLogadoId'))
        .then((res) => {
          this.form.participanteFornecedorId = res.data.id;
          this.form.participante = {
            value: res.data.id,
            text: res.data.apelido,
          };
          this.keyAtualiza++;
          this.desabilitaParticipanteFornecedor = true;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RegistroManutencaoTrocaPecaService.buscar(this.id)
        .then((res) => {
          this.form = new RegistroManutencaoTrocaPecasModel(res.data);
          this.form.instrumentoId = res.data.instrumento?.id;
          this.form.ocorrenciaId = res.data.ocorrencia?.id;
          this.form.participanteFornecedorId =
            res.data.participanteFornecedor?.id;
          this.form.usuarioRegistroId = res.data.usuarioRegistro?.id;
          this.form.usuarioRegistroNome = res.data.usuarioRegistro?.nome;

          this.form.instrumento = {
            value: res.data.instrumento?.id,
            text: res.data.instrumento?.codigo,
          };

          if (res.data.ocorrencia?.id) {
            this.form.ocorrencia = {
              value: res.data.ocorrencia?.id,
              text: res.data.ocorrencia?.nome,
            };
          }
          this.form.participante = {
            value: res.data.participanteFornecedor?.id,
            text: res.data.participanteFornecedor?.apelido,
          };
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.registro_manutencao_troca_pecas.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      RegistroManutencaoTrocaPecaService.salvar(this.form)
        .then(() => {
          this.redirecionaRota();
          this.toastSucesso(
            this.$t(`modulos.registro_manutencao_troca_pecas.cadastro_sucesso`)
          );
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    redirecionaRota() {
      if (!this.$route.params.instrumentoId) {
        this.$router.push({ name: 'registro-manutencao-troca-pecas' });
      } else {
        this.$router.push({
          name: 'registro-manutencao-troca-pecas-instrumento',
          params: {
            id: this.$route.params.instrumentoId,
            codigo: this.$route.params.codigo,
          },
        });
      }
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.redirecionaRota();
      });
    },
    listarTipoManutencao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnumeradorService.buscar('EnumTipoManutencao')
        .then((res) => {
          res.sort(helpers.ordenarDropdown);
          this.opcoes.tipoManutencao = new EnumModel(res, 'EnumTipoManutencao');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarLocalManutencao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnumeradorService.buscar('EnumLocalManutencao')
        .then((res) => {
          res.sort(helpers.ordenarDropdown);
          this.opcoes.localManutencao = new EnumModel(
            res,
            'EnumLocalManutencao'
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarPrazoAcordado: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnumeradorService.buscar('EnumTipoPrazoAcordado')
        .then((res) => {
          this.opcoes.prazoAcordado = new EnumModel(
            res,
            'EnumTipoPrazoAcordado'
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
