import { BasicoApi } from '@common/api';
const RegistroManutencaoTrocaPecaService = {
  listar: function (parametros) {
    return BasicoApi.get()(
      '/instrumentoRegistroManutencaoTrocaPeca',
      parametros
    );
  },
  buscar: function (id) {
    return BasicoApi.get()(`/instrumentoRegistroManutencaoTrocaPeca/${id}`);
  },
  salvar: function (form) {
    if (form.id)
      return BasicoApi.put()(
        `/instrumentoRegistroManutencaoTrocaPeca/${form.id}`,
        form
      );
    return BasicoApi.post()('/instrumentoRegistroManutencaoTrocaPeca', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/instrumentoRegistroManutencaoTrocaPeca/${id}`);
  },
};

export default RegistroManutencaoTrocaPecaService;
